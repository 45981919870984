/* App.css */

/* General App Layout */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden; 
  overflow-y: auto;
  scroll-behavior: smooth;
}

.app {
  display: flex;
  background-color: #ffffff;
}

.main-content {
  flex: 4;
  padding: 20px;
  background-color: #fff;
  margin-top: 60px; /* Adjust to avoid overlap with fixed top menu */
  padding-bottom: 800px; /* Extra space to ensure bottom content is visible */
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 100px 0;
}

.pagination button {
  background-color: transparent;
  border: 1px solid #ccc;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #ccc;
}

.pagination button:hover {
  background-color: #eee;
}

/* CROPPING (Only if you still need it. Otherwise remove. ) */
.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cropper-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.cropper-container {
  position: relative;
  width: 400px;
  height: 400px;
  background-color: #333;
}

.cropper-controls {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-container {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group button {
  padding: 8px 16px;
  cursor: pointer;
}

.cropped-image-preview {
  margin-top: 10px;
}

.cropped-image-preview img {
  max-width: 200px;
  max-height: 200px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }
}
