.account-settings-container {
    max-width: 400px;
    margin: 80px auto 20px auto; /* Centers the container and gives top spacing similar to login page */
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Header styling */
.account-settings-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

/* Current username display */
.account-settings-container p {
    text-align: center;
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
}

/* Actions for switching between forms */
.account-settings-container .account-actions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.account-settings-container .account-actions button {
    padding: 10px 20px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1rem;
}

.account-settings-container .account-actions button:hover {
    background-color: #0056b3;
    color: #fff;
}

/* Form styling */
.account-settings-container form {
    display: flex;
    flex-direction: column;
}

/* Section heading for forms */
.account-settings-container h3 {
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

/* Label and input grouping */
.account-settings-container label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 1rem;
    color: #333;
}

/* Input fields */
.account-settings-container input[type="text"],
.account-settings-container input[type="password"] {
    padding: 10px 12px;
    border: 1px solid #000;
    font-size: 1rem;
    transition: border-color 0.3s;
    margin-top: 5px;
}

/* Focus state for inputs */
.account-settings-container input[type="text"]:focus,
.account-settings-container input[type="password"]:focus {
    border-color: #5cb85c;
    outline: none;
}

/* Form action buttons (Update / Cancel) */
.account-settings-container .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.account-settings-container .form-actions button {
    padding: 10px;
    border: 1px solid #000;
    color: #000000;
    background-color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 0;
}

.account-settings-container .form-actions button:hover {
    background-color: #0056b3;
    color: #fff;
    border-radius: 0;
}

/* Error and success message styling (if any) */
.account-settings-container .error {
    color: #d9534f;
    font-size: 0.875rem;
    margin-top: 5px;
    text-align: center;
}

.account-settings-container .success {
    color: #5cb85c;
    font-size: 1rem;
    text-align: center;
    margin-top: 15px;
}

/* Responsive adjustments */
@media (max-width: 500px) {
    .account-settings-container {
        padding: 15px;
        margin: 60px 10px 20px 10px;
    }

    .account-settings-container h2 {
        font-size: 1.3rem;
    }

    .account-settings-container input[type="text"],
    .account-settings-container input[type="password"] {
        font-size: 0.95rem;
    }

    .account-settings-container button,
    .account-settings-container .form-actions button {
        font-size: 0.95rem;
        padding: 8px;
    }

    .account-settings-container p {
        font-size: 0.9rem;
    }
}
