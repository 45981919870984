/* loading-overlay.css */
/* .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; 
  }
  
  .loading-gif {
    width: 50px;
    height: 50px;
  }
   */

/* loading-overlay.css */

/* The parent container where the spinner appears */
.set-item {
  position: relative; /* so child position:absolute is anchored to this box */
}

/* The spinner container is pinned, no layout shift */
.set-item-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* No margin/padding that would push content */
  margin: 0;
  pointer-events: none; 
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;    /* or transparent, so it doesn't block or shift content */
  z-index: 1;          /* ensure it appears above the image */
}

/* The spinner icon itself */
.set-item-spinner {
  font-size: 2rem;
  color: #333;
}

/* If you hide the image while loading, these classes won’t cause reflow either */
.set-item-hidden {
  opacity: 0;
  pointer-events: none;
}
.set-item-loaded {
  opacity: 1;
  transition: opacity 0.3s;
}
