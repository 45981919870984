/* RadioAdmin.css */

.radioadmin-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
  }
  
  /* Center tabs if you want via CSS instead of inline style: */
  .radioadmin-tabs-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    /* justify-content: center;  <-- you can do it here if you prefer */
  }
  
  .radioadmin-tab-button {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
  }
  
  .radioadmin-tab-button.active {
    background-color: #28a745;
    color: #fff;
  }
  
  /* Table styling, etc. */
  .radioadmin-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .radioadmin-table th,
  .radioadmin-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  /* Modal backdrop */
  .radioadmin-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .radioadmin-modal {
    background-color: #fff;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 95%;
    box-sizing: border-box;
  }
  
  /* ADJUSTMENT #2: DJ pic tooltip styling */
  .dj-pic-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dj-pic-small {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .dj-pic-tooltip {
    position: absolute;
    top: 0;
    left: 50px;
    background-color: #fff;
    border: 1px solid #999;
    z-index: 999;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  }
  
  .dj-pic-large {
    width: 150px;
    height: auto;
    display: block;
    border-radius: 3px;
  }
  
  /* For truncated text fields: */
  .truncated-field {
    position: relative;
    display: inline-block;
    cursor: pointer;
    max-width: 200px; /* optional: limit table cell width */
    white-space: nowrap;
    text-overflow: ellipsis;
  }