/* WeChatHome.css */

/* Container styling from your snippet */
.wechat-home-container {
  max-width: 600px;
  margin: 0 auto; /* horizontally center the entire content */
  padding: 1rem;
}

/* If you have timeslot buttons somewhere */
.timeslot-button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  background: #eee;
  border: 1px solid #bbb;
  cursor: pointer;
}

.timeslot-button.booked {
  background: #fcdcdc;
  cursor: not-allowed;
}

/* 
  These classes ensure the calendar day cells 
  have a consistent size/padding, whether or not events are loaded.
*/
.react-datepicker__day--generic,
.react-datepicker__day--unavailable,
.react-datepicker__day--user-booked {
  /* Keep the day cell uniform */
  display: inline-block;
  /* width: 2rem; */
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  position: relative;
}

/* Example: grey out the 'unavailable' if you want. */
.react-datepicker__day--unavailable {
  color: #aaa !important;
  background-color: transparent !important;
}

/* Example: highlight user booked in a custom color if you like. */
.react-datepicker__day--user-booked {
  background-color: #ffd1dc !important; /* pinkish background */
  color: #000 !important;
}

