.admin-container {
  max-width: 1200px;
  margin: 60px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-container .admin-content {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.admin-container .left-panel {
  flex: 1;
  border: 1px solid #ccc;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 4px;
}

.admin-container .left-panel h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.admin-container .right-panel {
  flex: 2;
}

.admin-container .existing-event-section,
.admin-container .new-event-section {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
}

.admin-container .existing-event-section h3,
.admin-container .new-event-section h3 {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.admin-container .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.admin-container .form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.95rem;
}

.admin-container .form-group input[type="text"],
.admin-container .form-group input[type="number"],
.admin-container .form-group input[type="date"],
.admin-container .form-group input[type="file"],
.admin-container .form-group select,
.admin-container .form-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background: #fff;
}

.admin-container textarea {
  resize: vertical;
  min-height: 60px;
}

.admin-container .timeslots-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.admin-container .timeslot-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background: #e9e9e9;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background 0.3s;
}

.admin-container .timeslot-button:hover {
  background: #dcdcdc;
}

.admin-container .timeslot-button.selected {
  background: #28a745;
  color: #fff;
  border-color: #218838;
}

/* Buttons */
.admin-container .add-slots-button,
.admin-container .edit-button,
.admin-container .cancel-button,
.admin-container .delete-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  transition: background 0.3s;
  margin-top: 10px;
}

/* Primary (Add, Edit, Save) */
.admin-container .add-slots-button,
.admin-container .edit-button {
  background: #007bff !important;
}
.admin-container .add-slots-button:hover,
.admin-container .edit-button:hover {
  background: #0056b3 !important;
}

/* Cancel button */
.admin-container .cancel-button {
  background: #6c757d !important;
  margin-left: 10px;
}
.admin-container .cancel-button:hover {
  background: #5a6268 !important;
}

/* Download and Delete buttons */
.admin-container .download-button {
  background: #17a2b8 !important;
}
.admin-container .download-button:hover {
  background: #138496 !important;
}

.admin-container .delete-button {
  background: #dc3545 !important;
  font-size: 0.9rem;
  padding: 5px 10px;
}
.admin-container .delete-button:hover {
  background: #c82333 !important;
}

/* Event Poster */
.admin-container .event-poster img {
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}

.admin-container .existing-performances h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.admin-container .existing-performances ul {
  list-style: none;
  padding: 0;
}

.admin-container .existing-performances li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

/* Partner, Label, Location - arrow button */
.admin-container .arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 5px;
  color: #333;
}
.admin-container .arrow-button:hover {
  color: #000;
}

/* Cropper Modal Overrides */
.admin-container .cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
}

.admin-container .cropper-wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.admin-container .cropper-wrapper .reactEasyCrop_Container {
  width: 300px;
  height: 300px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-container .slider-container {
  width: 200px;
  margin: 10px 0;
}

.admin-container .button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.admin-container .button-group button {
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  background: #f7f7f7;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  transition: background 0.3s;
}

.admin-container .button-group button:hover {
  background: #e2e2e2;
}

.admin-container .cropped-image-preview img {
  max-width: 150px;
  margin-top: 10px;
}

/* New fields sections */
.admin-container .new-label-fields,
.admin-container .new-partner-fields,
.admin-container .new-location-fields {
  margin-top: 10px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 4px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-container .new-label-fields input[type="text"],
.admin-container .new-partner-fields input[type="text"],
.admin-container .new-location-fields input[type="text"],
.admin-container .new-label-fields textarea,
.admin-container .new-partner-fields textarea,
.admin-container .new-location-fields textarea {
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.admin-container .new-label-fields input[type="file"],
.admin-container .new-partner-fields input[type="file"],
.admin-container .new-location-fields input[type="file"] {
  font-size: 0.85rem;
}

.admin-container .new-label-fields label,
.admin-container .new-partner-fields label,
.admin-container .new-location-fields label {
  font-size: 0.9rem;
  font-weight: normal;
}

/* Additional spacing for the form buttons */
.admin-container .form-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

/* Responsive */
@media (max-width: 800px) {
  .admin-container .admin-content {
    flex-direction: column;
  }

  .admin-container .left-panel, 
  .admin-container .right-panel {
    flex: 1;
  }

  .admin-container .timeslots-buttons {
    flex-direction: column;
  }

  .admin-container .new-label-fields,
  .admin-container .new-partner-fields,
  .admin-container .new-location-fields {
    flex-direction: column;
  }

  .admin-container .add-slots-button, 
  .admin-container .edit-button, 
  .admin-container .cancel-button {
    width: 100%;
    text-align: center;
  }

  .admin-container .button-group button {
    width: 100%;
    text-align: center;
  }
}
