/* src/playlists/PlaylistAdminCMS.css */
.playlist-admin-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.playlist-admin-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.5rem;
  color: #333;
}

.loading-indicator {
  text-align: center;
  margin: 50px 0;
  font-size: 1rem;
}

.admin-content {
  display: flex;
  gap: 20px;
}


.search-container {
  margin: 10px 0;
}
.search-container input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #000;
  font-size: 0.9rem;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
  font-size: 0.9rem;
  border-top: 1px solid #000;
}

.item-list li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.item-list li.selected {
  background-color: #28a745;
  color: #fff;
}

.item-list li:hover:not(.selected) {
  background-color: #f0f0f0;
}

.no-items {
  padding: 10px;
  color: #666;
  font-style: italic;
}

/* DETAIL PANEL */
.detail-panel {
  flex: 1;
  border: 1px solid #000;
  padding: 20px;
  background-color: #f8f8f8;
}

.detail-panel h3 {
  margin-top: 0;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.detail-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #333;
}

.detail-form input[type="text"],
.detail-form textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #000;
  margin-top: 5px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.detail-form textarea {
  height: 60px;
  resize: vertical;
}

.buttons-row {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}




/* Responsive */
@media (max-width: 768px) {
  .admin-content {
    flex-direction: column;
  }
}
