/* DjSets.css */

/* DJ Sets Grid */
.dj-sets {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
  }
  
  /* Individual Set Item */
  .set-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
    border: 1px solid #aaa;
  }
  
  .set-item img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  /* Video snippet overlay */
  .set-item video.set-snippet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  /* Hover Effects */
  .set-item:hover img {
    transform: scale(1.05);
    filter: blur(5px);
  }
  
  .set-item:hover video.set-snippet {
    opacity: 1;
  }
  
  .set-item p {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 3px;
    margin: 0;
  }
  
  /* DJ Info Container */
  .dj-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .dj-info .dj-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .dj-info .dj-bio {
    margin-top: 10px;
    font-size: 0.8rem;
    line-height: 1.5;
  }
  
  /* Container for the sets */
  .dj-sets-container {
    margin: 0 20px 20px 20px;
    padding-bottom: 72px;
  }
  
  /* Next-week or upcoming lineups */
  .lineup-item {
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: left;
    background-color: #fff;
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 15px; 
  }
  
  .lineup-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    animation-fill-mode: forwards;
  }
  
  .lineup-section {
    padding: 0 10px;
  }
  
  .lineup-event-date {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
    line-height: 2;
  }
  
  .lineup-event-name {
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
    line-height: 1.5;
  }
  
  .lineup-event-location {
    font-size: 11px;
    font-weight: normal;
    margin: 7px 0 0 0;
    padding-bottom: 10px;
    border-bottom: 1px dotted #ccc;
  }
  
  .lineup-lineup-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .highlight-snippets-carousel {
    display: flex;
    flex-direction: row;
    overflow-x: auto;        /* horizontal scroll */
    gap: 16px;               /* spacing between snippet items */
    scroll-behavior: smooth; /* optional smooth scroll */
    /* If you want to hide the scrollbar or customize it, add your styling here */
  }
  
  .highlight-snippet-item {
    flex-shrink: 0;    /* so they don’t shrink when we overflow */
    min-width: 300px;  /* or whatever width makes sense for your design */
  }
  
  hr {
    opacity: 0.3;
  }
  
  /* AutoScroll for next-week events */
  .scroll-container {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .scroll-content {
    animation: scroll-up 12s linear infinite;
  }
  
  @keyframes scroll-up {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  /* Responsiveness */
  @media (max-width: 1024px) {
    .dj-sets {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .dj-sets {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .dj-sets-container {
      margin: 0;
    }
  
    .dj-sets {
      gap: 12px;
    }
  }
  