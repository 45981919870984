.workshop-container {
  max-width: 1200px;
  padding: 20px;
  background-color: #fff;
}

.workshop-container button {
  font-size: 0.8rem;
  padding: 2px 5px;
}

.workshop-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.5rem;
  color: #333;
}

.workshop-tabs-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.workshop-tab-button {
  padding: 5px 8px!important;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  font-size: 0.8rem!important;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

.workshop-tab-button.active {
  background-color: #28a745;
  color: #fff;
}

.workshop-tab-button:hover:not(.active) {
  background-color: #f0f0f0;
}

.workshop-loading-indicator {
  text-align: center;
  margin-top: 50px;
  font-size: 1rem;
}

/* For the calendar tab layout */
.workshop-admin-content {
  display: flex;
  gap: 20px;
}

/* Sidebar used in calendar tab */
.workshop-sidebar {
  flex: 0 0 40%;
  border: 1px solid #000;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.workshop-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.workshop-sidebar-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

/* "Create New" button styling */
.workshop-create-button {
  padding: 5px 10px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, color 0.3s;
}
.workshop-create-button:hover {
  background-color: #28a745;
  color: #fff;
}

/* Search container: float right, narrower width for input */
.workshop-search-container {
  margin-bottom: 10px;
  text-align: right; /* to move input to the right */
}
.workshop-search-container input[type="text"] {
  width: 200px;
  padding: 5px;
  border: 1px solid #000;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/* Table listing wrapper for non-calendar tabs */
.workshop-list-table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.workshop-list-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.workshop-list-table th,
.workshop-list-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.workshop-list-table th {
  background-color: #fff;
}

.workshop-no-items {
  padding: 10px;
  color: #666;
  font-style: italic;
  text-align: center;
}

/* Right detail panel for calendar tab only */
.workshop-detail-panel {
  width: 100%;
  border: 1px solid #000;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.workshop-detail-panel h3 {
  margin-top: 0;
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
}

/* For the timetable-like styling on the calendar detail */
.calendar-detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.calendar-detail-table th,
.calendar-detail-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

/* Detail form elements in the modal */
.workshop-detail-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #333;
}

.workshop-detail-form input[type="text"],
.workshop-detail-form input[type="number"],
.workshop-detail-form input[type="email"],
.workshop-detail-form input[type="date"],
.workshop-detail-form input[type="time"],
.workshop-detail-form textarea,
.workshop-detail-form select {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 0.9rem;
  background: #fff;
  box-sizing: border-box;
  border-radius: 3px;
}

.workshop-detail-form textarea {
  height: 60px;
  resize: vertical;
}

/* Buttons */
.workshop-save-button,
.workshop-cancel-button,
.workshop-edit-button,
.workshop-convert-button {
  padding: 5px 8px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 3px;
  margin: 2px;
}

.workshop-save-button:hover,
.workshop-convert-button:hover {
  background-color: #28a745;
  color: #fff;
}
.workshop-edit-button:hover {
  background-color: #007bff;
  color: #fff;
}
.workshop-cancel-button:hover {
  background-color: #dc3545;
  color: #fff;
}

/* Responsive for calendar panel, etc. */
@media (max-width: 768px) {
  .workshop-admin-content {
    flex-direction: column;
  }
  .workshop-sidebar,
  .workshop-detail-panel {
    margin-bottom: 20px;
    flex: none;
  }
  .workshop-detail-panel {
    width: auto;
  }
}

/* -------------------------------------- */
/* MODAL STYLES (detail form on non-calendar tabs) */
/* -------------------------------------- */
.workshop-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.workshop-modal {
  background-color: #fff;
  border: 1px solid #000;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  box-sizing: border-box;
}

.workshop-modal-content label {
  display: block;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.workshop-modal-content input,
.workshop-modal-content select,
.workshop-modal-content textarea {
  width: 100%;
  margin-top: 5px;
  border: 1px solid #000;
  font-size: 0.9rem;
  padding: 5px;
  border-radius: 3px;
  box-sizing: border-box;
}

.workshop-modal-content h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

/* For the inquiries table specifically, use fixed layout + single-line ellipsis */
.workshop-list-table.inquiries-table {
  table-layout: fixed; 
  width: 100%;
}

/* Single-line ellipsis style */
.inquiries-ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.5em;
  line-height: 1.5em;
}