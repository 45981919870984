/* src/DjProfile.css */

.dj-profile-container {
    max-width: 420px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #000;
    text-align: center;
  }
  
  .dj-profile-container h2 {
    text-align: center;
    margin-bottom: 50px;
    color: #343a40;
  }
  
  .profile-view, .profile-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-details {
    width: 80%;
    margin-bottom: 20px;
  }
  
  .profile-pic {
    width: 180px;
    height: 180px;
    /* border-radius: 50%; */
    object-fit: cover;
    border: 1px solid #000;
    margin-bottom: 20px;
    margin: 0 auto;
  }
  
  .profile-pic-preview {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: 1px solid #000;
    margin-bottom: 20px;
  }
  
  .profile-details p {
    margin: 8px 0;
    font-size: 0.85rem;
    color: #495057;
    line-height: 1.5;
  }
  
  .edit-button, .save-button, .cancel-button {
    padding: 10px 20px;
    /* margin: 10px; */
    border: 1px solid #000;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .edit-button {
    transition: background-color 0.3s;
    background-color: #fff!important;
    border: 1px solid #000!important;
  }

  .booking-button {
    transition: background-color 0.3s;
    background-color: #fff!important;
    border: 1px solid #000!important;
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid #000;
    cursor: pointer;
    font-size: 1rem; 
  }
  
  .edit-button:hover {
    background-color: #218838!important;
    color:#fff!important;
  }
  
  .save-button {
    transition: background-color 0.3s;
    border-radius: 0!important;
    background-color: #fff!important;
  }
  
  .save-button:hover {
    background-color: #218838!important;
    color: #fff!important;
    border: 1px solid #fff;
  }
  
  .cancel-button {
    background-color: #333;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  
  .profile-edit form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .profile-edit label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-weight: 600;
    color: #343a40;
  }
  
  .profile-edit input[type="text"],
  .profile-edit input[type="file"],
  .profile-edit textarea {
    padding: 10px;
    border: 1px solid #000;
    margin-top: 5px;
    font-size: 1rem;
  }
  
  .profile-edit textarea {
    resize: vertical;
    min-height: 80px;
  }

  .profile-edit label {
    text-align: left;
  }

  .current-profile-pic {
    margin-top: 20px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .error {
    color: #dc3545;
    text-align: center;
    margin-top: 15px;
  }
  
  .success {
    color: #28a745;
    text-align: center;
    margin-top: 15px;
  }
  