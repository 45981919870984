/* MusicPlayer.css */

/* Main Music Player Container */
.music-player {
  position: fixed;
  bottom: 0;
  height: 50px;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #111;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid #333;
  box-sizing: border-box;
  z-index: 1000;
}

/* Set Info */
.set-info {
  min-width: 80px;
  width: auto;
  transition: width 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 10px;
}
.set-info-main {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 12px;
  font-weight: bold;
}
.set-info-secondary {
  font-size: 10px;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
}
.set-info-placeholder {
  font-size: 13px;
  color: #000;
}

/* Transition classes for Slide */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}
.slide-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}

/* Marquee effect for event name */
.marquee-container {
  width: 100%;
  overflow: hidden;
}
.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}
@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

/* Buttons */
.cue-btn,
.play-pause-btn {
  background: #fff;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-bottom: 10px;
}

/* On mobile view, shrink the player buttons further */
@media (max-width: 640px) {
  .cue-btn,
  .play-pause-btn {
    width: 40px !important;
    height: 40px !important;
    margin-top: 10px !important;
  }
}

/* Progress Container */
.progress-container {
  position: relative;
  width: 100%;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

/* Range Input Styling */
.progress-container input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  background: #000;
  height: 1px;
  border-radius: 2px;
  outline: none;
  margin: 0;
}

/* Hide native slider thumb (transparent but functional) */
.progress-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 25px;
  background: transparent;
  border-radius: 20%;
  cursor: pointer;
  opacity: 0;
}
.progress-container input[type="range"]::-moz-range-thumb {
  width: 6px;
  height: 25px;
  background: transparent;
  border-radius: 20%;
  cursor: pointer;
  opacity: 0;
}

/* Marker Styling */
.major-marker,
.minor-marker {
  position: absolute;
  transform: translateX(-50%);
  pointer-events: none;
}
.major-marker {
  top: -4px;
  height: 8.5px;
  border-left: 1.5px solid #666;
  border-radius: 30%;
  z-index: 5;
}
.marker-label {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: #555;
  pointer-events: none;
}
.minor-marker {
  top: -2px;
  height: 5px;
  border-left: 1px dotted #aaa;
}

/* Current Playback Marker (Emoji) */
.current-timestamp-marker {
  position: absolute;
  top: -22px;
  font-size: 26px;
  z-index: 10;
  pointer-events: none;
  transform: translateX(-50%);
}

/* Cue Point Marker */
.cue-point-marker {
  position: absolute;
  top: 0;
  width: 2px;
  height: 12px;
  background-color: red;
  pointer-events: none;
}

/* Timer Display */
.music-player-timer {
  font-size: 11px;
  white-space: nowrap;
  flex-shrink: 0;
  margin-left: 10px;
}

/* Hide timer on mobile view */
@media (max-width: 640px) {
  .music-player-timer {
    display: none;
  }
}
