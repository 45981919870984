/* src/Login.css */

/* Container for the login form */
.login-container {
    max-width: 400px; /* Slightly narrower than apply-container */
    margin: 80px auto 20px auto; /* Centers the container with top margin */
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Header styling */
.login-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

/* Form styling */
.login-container form {
    display: flex;
    flex-direction: column;
}

/* Label and input grouping */
.login-container label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 1rem;
    color: #333;
}

/* Input fields */
.login-container input[type="text"],
.login-container input[type="password"] {
    padding: 10px 12px;
    border: 1px solid #000;
    font-size: 1rem;
    transition: border-color 0.3s;
    margin-top: 5px;
}

/* Focus state for inputs */
.login-container input[type="text"]:focus,
.login-container input[type="password"]:focus {
    border-color: #5cb85c;
    outline: none;
}

/* Submit button */
.login-container button[type="submit"] {
    padding: 10px;
    border: 1px solid #000;
    color: #000000;
    font-size: 1rem;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
	border-radius: 0;
}

/* Hover state for submit button */
.login-container button[type="submit"]:hover {
    background-color: #0056b3;
    color: #fff;
	border-radius: 0;
}

/* Error message styling */
.login-container .error {
    color: #d9534f;
    font-size: 0.875rem;
    margin-top: 5px;
    text-align: center;
}

/* Success message styling */
.login-container .success {
    color: #5cb85c;
    font-size: 1rem;
    text-align: center;
    margin-top: 15px;
}

/* Link styling */
.login-container p {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
}

.login-container a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
}

.login-container a:hover {
    color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 500px) {
    .login-container {
        padding: 15px;
        margin: 60px 10px 20px 10px;
    }

    .login-container h2 {
        font-size: 1.3rem;
    }

    .login-container input[type="text"],
    .login-container input[type="password"] {
        font-size: 0.95rem;
    }

    .login-container button[type="submit"] {
        font-size: 0.95rem;
    }

    .login-container p {
        font-size: 0.85rem;
    }
}
