/* src/calendar/RadioCalendar.css */
.radio-calendar-container {
    margin: 1rem auto;
    max-width: 1200px;
  }
  
  .rbc-toolbar-label {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .rbc-event {
    background-color: #00A86B; /* Example green */
    border-radius: 4px;
    color: white;
    border: none;
  }
  