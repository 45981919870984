/* Container for the entire page */
.detailContainer {
    padding: 24px; /* or 2rem, etc. */
  }
  
  /* Top bar area with back arrow, event title, etc. */
  .topBar {
    display: flex;
    align-items: center;
    margin-bottom: 16px; /* 1rem */
  }
  
  /* If you want a narrower spacing:
  .topBar {
    margin-bottom: 1rem;
  }
  */
  
  /* The left image area is bigger; the right controls smaller text */
  .leftSide {
    /* We'll rely on grid area in the .jsx for 8 columns vs. 4 columns */
    /* Additional styling if desired. */
  }
  
  .rightSide {
    line-height: 1.2;
    font-size: 0.9rem;
  }
  
  /* The box that shows the best thumbnail or screenshot canvas */
  .imageBox {
    width: 100%;
    border: 1px solid #ccc;
    display: block;
    max-width: 100%;
  }
  
  /* If there's no screenshot */
  .noScreenshot {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* The main Paper wrapping each Performance item */
  .performancePaper {
    padding: 16px;
    margin: 16px 0;
  }
  
  /* For the Thumbnails section: 2 columns in a grid */
  .thumbnailGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px; /* space between items */
  }
  
  .thumbnailItem {
    border: 1px solid gray;
    padding: 8px;
    /* optional: set a fixed height or something if you want */
  }
  
  /* If is_best, highlight it */
  .thumbnailItem.best {
    border: 3px solid blue;
  }
  
  .thumbnailImage {
    width: 100%;
    display: block;
  }
  
  /* If you want smaller spacing on Sliders/TextFields, 
     you can also override MUI classes or use .MuiSlider-root etc. 
     For a quick approach, just rely on MUI's size="small". */
  
  