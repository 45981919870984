/* TopMenu.css */

.top-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  margin: 0;
  background-color: white;
  border-bottom: 1px solid #888;
  z-index: 1000;
}

/* Logo */
.top-menu .logo {
  flex: 0 0 auto;
}
.top-menu .logo img {
  width: 120px;
}

/* Ticker */
.top-menu .ticker {
  flex: 1;
  overflow: hidden;
  position: relative;
  margin: 0 10px;
  border-left: 1px solid #888;
}

.top-menu .ticker-text {
  white-space: nowrap;
  display: inline-block;
  animation: ticker-animation 30s linear infinite;
  font-size: 0.85rem;
  animation-play-state: running;
}

.top-menu .ticker-text:hover {
  animation-play-state: paused;
}

@keyframes ticker-animation {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(95%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply or Book Button */
.apply-or-book {
  display: flex;
  align-items: center;
}

.apply-or-book-button {
  display: inline-block;
  padding: 7px 12px;
  margin-right: 10px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
  border-radius: 6px;
}

.apply-or-book-button:hover {
  background-color: #333;
}

/* Dropdown Menu */
.menu-dropdown {
  position: relative;
  display: inline-block;
  background-color: #fff;
  font-size: 1.1rem;
}

.dropdown-button {
  padding: 6px 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  /* background-color: #fff; */
  background-color: #1d6823;
  color: #fff;
  border-radius: 6px;
}

.dropdown-button:hover {
  background-color: #28a745;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 196px;
  z-index: 1;
  top: 34px;
  right: -10px;
  border: 1px solid #000;

  /* Start "hidden": */
  visibility: hidden;
  opacity: 0;
  transform: translateY(-8px); /* slight upward offset */
  pointer-events: none;

  /* Transition to happen over .3s (adjust as you like) */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content.show {
  /* The "visible" state: */
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}


.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border-top: 1px dotted #ccc;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
}

.dropdown-item:hover {
  background-color: #63dc7f;
}

.dropdown-item a {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.dropdown-item button {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

/* Responsive */
@media (max-width: 768px) {
  .top-menu .logo img {
    width: 90px !important;
  }
}
