/* Occupy the entire browser viewport */
.djset-detail-container {
    position: relative;
    display: flex;
    /* width: 100vw; */
    /* height: 100%; */
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
  }

  .djset-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  /* Back arrow in the top-left corner */
  .back-arrow {
    position: absolute;
    left: 0px;
    z-index: 10;
    cursor: pointer;
    color: #333;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #fff;
    padding: 8px 10px;
    /* border-radius: 4px; */
    top: 5px;
    left: 80px;
    border: 1px solid #000;
    border-radius: 50%;
  }

  .back-arrow:hover {
    background-color: #307728;
    color: #fff;
  }
  
  /* LEFT COLUMN */
  .djset-detail-left {
    flex: 3;
    height: 100%;
    max-width: 500px;
    overflow-y: auto; /* scrollable */
    box-sizing: border-box;
    /* Hide scrollbar in webkit browsers */
    scrollbar-width: none; /* for Firefox */
    margin-top: 10px;
  }
  
  .djset-detail-left::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, etc. */
  }
  
  /* A wrapper inside the left column so we can center content easily */
  .left-inner {
    max-width: 360px; /* or whatever fixed size you want */
    margin: 80px auto 20px auto; /* top margin to avoid back arrow, auto left/right for centering */
    padding: 0 10px;
  }
  
  .djset-detail-padding {
    flex: 1;
  }
  /* Right column */
  .djset-detail-right {
    flex: 2;
    position: relative;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 50px;
  }
  
  /* Thumbnail */
  .djset-detail-thumbnail {
    width: 100%;
    max-width: 360px;
    height: auto;
    display: block;
    margin-bottom: 10px;
    border: 1px solid #aaa;
  }
  
  /* Genre badges */
  .genre-badges {
    margin: 10px 0;
  }
  .genre-badge {
    display: inline-block;
    background-color: #eee;
    color: #333;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 5px;
    font-size: 0.rem;
  }
  
  /* Right column button group in the vertical center */
  .djset-button-group {
    position: fixed;
    top: 50%;
    left: 70%; 
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center;
    gap: 40px; /* space between buttons */
  }

  .left-button-group {
    display: none;
    right: 0;
    transform: translateY(20%);
    justify-content: center; /* center horizontally */
    align-items: center;
    gap: 50px; /* space between buttons */ 
  }
  
  /* Circular buttons */
  .play-stop-button,
  .like-button {
    width: 100px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1rem;
    cursor: pointer;
  }
  
  /* Play button */
  .play-stop-button {
    border: 2px solid black;
    background-color: #fff;
    color: #000;
  }
  .play-stop-button:hover {
    background-color: #333;
    color: #fff;
  }
  
  /* Like button */
  .like-button {
    border: none;
    background-color: #ff5588;
    color: #fff;
  }
  .like-button:hover {
    background-color: #ff3377;
  }

  .highlight-snippets {
    margin-top: 50px;
  }


  /* Container that scrolls horizontally with snapping */
  .snippet-carousel-container {
    display: flex;
    gap: 1px;                    /* space between items */
    overflow-x: auto;            /* horizontal scroll */
    scroll-snap-type: x mandatory; /* “x” direction, mandatory snapping */
    scroll-behavior: smooth;     /* optional smooth scroll */
    -webkit-overflow-scrolling: touch; /* improves iOS scrolling */
  }
  
  /* Each item snaps to the start (could use center if you prefer) */
  .snippet-carousel-item {
    scroll-snap-align: start;
    flex-shrink: 0;       /* so items don’t shrink beyond their content size */
    width: 80vw;          /* or any desired width for each video “slide” */
    max-width: 500px;     /* to prevent super wide items on big screens */
    box-sizing: border-box;
  }
  
  /* Make the video responsive (take full width of .snippet-carousel-item) */
  .snippet-video {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;  /* or contain; depends on your preference */
  }

  .label-section {
    margin: 40px 0;
    padding: 20px;
    border: 1px solid #333;
    background-color: #ffeefb;
  }

  .dj-info-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border-radius: 5px; */
    margin-bottom: 20px;
  }

  .dj-info-item h4 {
    margin: 10px 0 10px 0;
    font-size: 1.2rem;
  }
  
  .dj-performance-title {
    margin-top: 10px;
  }

  
  /* Responsive adjustments */
  @media (max-width: 768px) {


    .djset-detail-container {
      flex-direction: column;
      height: auto;
    }
  
    .djset-detail-left {
      width: 100%;
      height: auto;
      overflow-y: visible;
    }

    .dj-info-container{
        margin-top: 50px;
      }
  
    .left-inner {
      margin-top: 80px; /* same top margin as above */
    }
  
    .djset-detail-right {
      width: 100%;
      height: 400px; /* or something suitable for mobile */
      position: relative;
    }
    .button-group {
      display: none!important;
    }
    .left-button-group {
      display: flex; /* show on mobile */
    }
    .back-arrow {
      left: 10px;
    }

  }
