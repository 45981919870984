/* Sidebar.css */

/* Container and Basic Layout */
.sidebar {
  margin-top: 60px;
}

.sidebar-container {
  top: 0;
  left: 0;
  background-color: white;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  flex-shrink: 0;
  transform: translateX(-100%);
}

.sidebar-container.show {
  transform: translateX(0); /* Slide in the sidebar */
}

.sidebar-backdrop.show {
  display: block; /* Show the backdrop when the sidebar is open */
}

/* Headings and Items */
.sidebar-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.sidebar-container ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-container ul li {
  font-size: 0.8rem;
  color: #333;
  padding-bottom: 3px;
  cursor: pointer;
}

.sidebar-container ul li.sidebar-heading {
  font-size: 0.9rem; 
  font-weight: bold;
  padding-bottom: 5px;
}

.sidebar-container ul li:hover {
  color: #000;
  text-decoration: underline;
}

.sidebar-container ul li a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.sidebar-container ul li a:hover {
  text-decoration: underline;
}

/* Datepicker in Sidebar */
.sidebar-datepicker {
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px dotted #aaa;
}

.sidebar .react-datepicker {
  width: 100%;
  border: 0 !important;
  left: 10px;
}

.sidebar-container .react-datepicker__current-month {
  font-size: 1.15rem;
}

.sidebar .react-datepicker__header {
  background-color: #fff;
}

.sidebar .react-datepicker__day--outside-month {
  color: #ccc !important;
}

.sidebar .react-datepicker__day {
  margin: 0 !important;
}

/* Class names used in sidebarDayClassName() */
.sidebar-date--event {
  background-color: #81d294;
  color: #fff;
}

.sidebar-date--no-event {
  color: #aaa;
}

/* For custom event color */
.sidebar-date--custom {
  background-color: orange;
  color: #fff;
}

.sidebar-event-info {
  max-width: 250px;
  margin: 5px auto;
  text-align: left;
  font-size: 0.9rem;
}

/* Responsive */
@media (max-width: 768px) {
  .sidebar-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    z-index: 999;
  }

  .sidebar-container ul li {
    font-size: 1.3rem;
    padding-bottom: 5px;
  }

  .sidebar-container ul li.sidebar-heading {
    font-size: 1.4rem;
    padding-bottom: 8px;
  }
}
