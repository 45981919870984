.content-manage-container {
    max-width: 1200px;
    margin: 0px auto 200px auto; /* Similar top margin as Apply page */
    padding: 20px;
    background-color: #fff;
}

.content-manage-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
    font-weight: bold;
}

/* Grid layout for events */
.events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

/* Each event box */
.event-box {
    background-color: #fff;
    border: 1px solid #000;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-box:hover {
    background-color: #f9f9f9;
}

.event-info p {
    margin: 5px 0;
    font-size: 0.95rem;
}

/* Labels and partners container */
.labels-partners {
    display: flex;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
}

.labels, .partners {
    display: flex;
    gap: 5px;
}

.logo-image {
    width: 40px;
    height: 40px;
    object-fit: contain;
    background: #fff;
}

/* Lineup section */
.event-lineup {
    margin-top: 20px;
    border-top: 1px solid #000;
    padding-top: 10px;
}

.artist-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0; /* Adjust spacing as needed */
}

.artist-left {
    font-size: 0.9rem;
    /* Additional styling if needed */
}

.artist-right {
    font-size: 0.9rem;
    /* Additional styling if needed */
}

.event-lineup p {
    margin: 5px 0;
    font-size: 0.9rem;
    /* Remove justify-content if previously set */
    display: flex; 
    align-items: center;
    justify-content: flex-start;
}

.event-lineup p strong {
    margin-bottom: 5px;
}

/* Buttons to copy social handles */
.copy-buttons {
    display: flex;
    gap: 5px;
}

.copy-btn {
    padding: 2px 5px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s;
}

.copy-btn:hover {
    background-color: #ccc;
}

/* Download graphics link */
.download-graphics {
    margin-top: 20px;
    text-align: right;
}

.download-graphics a {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    font-size: 0.9rem;
    text-decoration: none;
    transition: background-color 0.3s;
}

.download-graphics a:hover {
    background-color: #0056b3;
    color: #fff;
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .content-manage-container {
        padding: 15px;
    }

    .event-box {
        padding: 15px;
    }

    .event-lineup p {
        flex-direction: column;
        align-items: flex-start;
    }
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
    gap: 10px;
}

.pagination-controls button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s;
}

.pagination-controls .active-page {
    background-color: #000;
    color: #fff;
}