/* src/WorkshopInquiry.css */

.workshop-inquiry-container {
    max-width: 600px;
    margin: 80px auto 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    line-height: 1.6; /* Increased line-height for all text */
}

.workshop-inquiry-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

/* Base form styling */
.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

/* Slightly bigger space for radio/checkbox groups */
.radio-group,
.checkbox-group {
    margin-bottom: 25px; /* More space from other text fields */
}

.form-group label {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 1rem;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
    padding: 8px 10px;
    border: 1px solid #000;
    font-size: 1rem;
    transition: border-color 0.3s;
    line-height: 1.4;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group textarea:focus {
    border-color: #5cb85c;
    outline: none;
}

.form-group input[type="radio"],
.form-group input[type="checkbox"] {
    margin-right: 6px;
}

.music-exp-options,
.time-pref-options {
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio-label,
.checkbox-label {
    display: block;
    margin-bottom: 6px;
}

.error {
    color: #d9534f;
    font-size: 0.875rem;
    margin-top: 5px;
}

.success-message p {
    line-height: 1.6;
}

.success-message {
    color: #5cb85c;
    text-align: center;
    margin-bottom: 15px;
    font-size: 1rem;
}

.workshop-inquiry-container button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.workshop-inquiry-container button[type="submit"]:hover {
    background-color: #0056b3;
    color: #fff;
}

.submit-error {
    color: #d9534f;
    text-align: center;
    margin-top: 10px;
}

.workshop-inquiry-container input::placeholder,
.workshop-inquiry-container textarea::placeholder {
    font-size: 0.875rem;
    color: #a9a9a9;
    opacity: 1;
}

/* Make the textarea a bit taller by default if needed */
.form-group textarea {
    min-height: 120px; /* or simply rely on rows="6" in the JS code */
}

@media (max-width: 600px) {
    .workshop-inquiry-container {
        padding: 15px;
    }

    .workshop-inquiry-container h2 {
        font-size: 1.3rem;
    }
}
