/* src/SidebarForecast.css */

.sidebar-forecast {
  margin-bottom: 1rem;
  padding: 0 0 10px 0;
  border-bottom: 1px dotted #aaa;
  font-family: sans-serif;
  font-size: 0.85rem;
}

.forecast-loading,
.forecast-error {
  font-size: 0.8rem;
  color: #555;
  margin: 5px 0;
}

/* Row of 7 days horizontally */
.forecast-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  overflow-x: auto; /* Let it scroll if needed */
}

.forecast-day-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 4px 0;
  border-radius: 4px; /* optional, can remove if you want */
}

/* Remove the old hover highlight on the entire column */
.forecast-day-col:hover {
  background-color: transparent; 
}

/* Day name (top row text) */
.forecast-dayname {
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 3px;
}

/* The date circle */
.forecast-datecircle {
  width: 24px;
  height: 24px;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: #333;
  background-color: #fff; 
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* If the day has events, show a small dot below the circle */
.forecast-dot {
  width: 6px;
  height: 6px;
  background-color: #f80;
  border-radius: 50%;
  margin-top: 3px;
}

/* When a day is active (clicked), only invert the circle */
.forecast-day-col.active .forecast-datecircle {
  background-color: #006400; /* dark green */
  color: #fff;
  border-color: #006400;
}

/* The detail area (below) */
.forecast-detail {
  background-color: #fff;
  /* border-top: 1px dotted #ccc; */
  padding-top: 5px;
  min-height: 30px;
}

.forecast-detail-block {
  margin-bottom: 8px;
}

.forecast-detail-block h4 {
  margin: 0 0 3px 0;
  font-size: 0.85rem;
}

.forecast-detail-block ul {
  margin: 0;
  padding-left: 1.2em;
}

/* Small responsive tweak */
@media (max-width: 768px) {
  .forecast-row {
    gap: 6px;
  }
  .forecast-day-col {
    min-width: 36px;
  }
  .forecast-datecircle {
    width: 22px;
    height: 22px;
    font-size: 0.7rem;
  }
}
