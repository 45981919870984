/* NewBooking.css */

/* Remove list-style from everything (like you already do) */


.step-one-description {
  margin: 50px auto 40px auto!important;
  max-width: 350px;
  line-height: 1.5!important;
  text-align: left;
  /* font-style: italic; */
  font-size: 0.9rem;
  border: 1px solid #000;
  padding: 20px 30px 20px 40px;
  background-color: #e3ffe9;
}
/* Container */
.new-booking-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px 0;
  background-color: #fff;
  text-align: center;
  position: relative;
  font-family: Arial, sans-serif;
}

/* Blurred content if loading */
.new-booking-content.blur {
  filter: blur(3px);
  transition: filter 0.3s ease;
}

.new-booking-description {
	margin: 30px auto 30px auto!important;
	max-width: 600px;
	line-height: 1.5!important;
	text-align: left;
  /* font-style: italic; */
  font-size: 0.8rem;
  border: 1px solid #000;
  padding: 20px 30px 20px 40px;
  background-color: #fffcdd;
  list-style: decimal outside;
  margin: 1em;
  padding-left: 1em;
  }

  
.new-booking-description li {
  margin: 1em 1em 1em 2em;
  padding-bottom: 1em;
  border-bottom: 1px dotted #aaa;
}

/* Steps */
.new-progress-indicator {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
}
.new-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}
.new-step-number {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.new-step.active .new-step-number,
.new-step.completed .new-step-number {
  background-color: #28a745;
  color: #fff;
}
.new-step-label {
  font-size: 0.8rem;
  color: #555;
}
.new-step.active .new-step-label,
.new-step.completed .new-step-label {
  color: #28a745;
}

/* Language toggle, back arrow => inlined in your JS with absolute positioning */

/* DatePicker => bigger, sharp corners */
.react-datepicker {
  border-radius: 0 !important;
  transform: scale(1.2) !important; 
  transform-origin: top center !important;
  border: 1px solid #000 !important;
}
.react-datepicker__day {
  border-radius: 0 !important;
}

/* If day is in the past => disabled */
.react-datepicker__day--disabled {
  color: #ccc !important;
  pointer-events: none !important;
}
.react-datepicker__day--unavailable {
  color: #666 !important;
  cursor: not-allowed !important;
  text-decoration: line-through;
}
.react-datepicker__day--user-booked {
  background-color: orange !important;
  color: #fff !important;
}

.react-datepicker__day--selected .react-datepicker__day--grid-span {
  background-color: blue !important; /* Blue background */
  color: white !important; /* White text */
}

.time-slot-header {
  margin-top: 50px;
}
/* timeslots-box for Step 3 */
.timeslots-box {
  background-color: #d4ffd4; /* light green */
  border: 1px solid #000;
  padding: 30px;
  margin-top: 1rem;
}

.new-timeslots-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

/* Timeslot buttons */
.new-timeslot-button {
  padding: 14px 20px;
  font-size: 1.05rem;
  background-color: #fff;
  color: #000;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}
.new-timeslot-button:hover {
  background-color: #28a745;
  color: #fff;
  transform: scale(1.05);
}
.new-timeslot-button.selected {
  background-color: #28a745;
  color: #fff;
}
.new-timeslot-button.booked {
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0.1) 20px
  );
}
.new-timeslot-button.booked-by-user {
  background-color: #28a745;
  color: #fff;
  cursor: default;
}

/* The event info box => background color dynamic in JS => eventDetails.event_color */
.event-details-box {
  border: 1px solid black;
  font-style: italic;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  /* color set in JS: style={{ backgroundColor: eventDetails.event_color }} */
}

.event-details-box h3 {
  font-size: 1.1rem; 
  text-align: center;
  margin-top: 2rem;
}

.event-details-box-date {
  text-align: center;
  font-size: 0.9rem; 
}

/* Step 5 => Confirmation box */
.new-booking-summary {
  max-width: 350px;
  margin: 30px auto;
  background-color: #f8f8f8;
  border: 1px solid #000;
  padding: 20px;
  text-align: left;
}

/* Performance Type + Mixer => normal border */
.new-performance-type-selection,
.new-mixer-model-selection {
  max-width: 300px;
  margin: 30px auto;
  background-color: #f8f8f8;
  border: 1px solid #000;
  padding: 15px 20px;
  text-align: center;
}
.new-performance-type-selection label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
}
.new-performance-type-selection input[type='radio'] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  accent-color: #28a745;
}
.new-mixer-model-selection select {
  width: 100%;
  padding: 8px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.new-mixer-model-selection select:hover {
  background-color: #28a745;
  color: #fff;
}

.live-requirement-field {
  max-width: 300px;
  margin: 30px auto;
  border: 1px solid #000;
  padding: 15px 20px;
  text-align: center;
}
/* Next/Prev => thicker border (inline in JS) 
   Confirm => normal border 
*/
.new-next-button,
.new-previous-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  /* border: 2px solid black => done inline style in JS */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  margin: 5px;
}
.new-next-button:hover,
.new-previous-button:hover {
  background-color: #28a745;
  color: #fff;
  transform: scale(1.03);
}


.new-confirm-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin: 5px;
}
.new-confirm-button:hover {
  background-color: #28a745;
  color: #fff;
}

/* Overlay */
.new-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.new-loading-gif {
  width: 30px;
  height: 30px;
}

.red-error-box {
  background-color: #f8d7da;  /* Pale red background */
  border: 1px solid #f5c2c7;  /* Slightly darker red border */
  color: #721c24;             /* Dark red text */
  padding: 15px;
  margin: 15px 0;
  text-align: left;
  font-size: 0.9rem;
}

.special-info-box {
  margin-top: 20px;
  background-color: #ffe7e9;
  border: 1px solid #000000;
  padding: 15px;
}