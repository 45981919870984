/* Container styled similar to ContentEventDetail and ContactUs */
.my-bookings-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  position: relative;
}

/* Language toggle button in the top right */
.language-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 10px;
}

/* Heading */
.my-bookings-container h2 {
  margin: 15px 0 40px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

/* List of bookings – each booking takes up one row */
.bookings-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

/* Common style for each row */
.booking-box {
  display: flex;
  border: 1px solid #000;
  background: #fff;
  transition: background-color 0.3s;
  align-items: flex-start;
  cursor: pointer;
}

.booking-box:hover {
  background-color: #fdfdfd;
}

/* Left thumbnail area */
.booking-thumbnail {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #fdfdfd;
}

.booking-plus-icon {
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #fdfdfd;
  transition: background-color 0.3s, transform 0.2s;
}

.new-booking-box :hover {
  background-color: #7dec8c!important;
  transform: scale(1.02);
  border: 1px solid #000;
}

.booking-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

/* For the New Booking box, show a plus icon */
.plus-icon {
  font-size: 3rem;
  color: #000;
}

/* Booking info on the right */
.mybookings-booking-info {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  flex: 1;
  font-size: 0.85rem;
  border: none!important;
}

.mybookings-booking-info p {
  margin: 5px 0;
}

/* Details button */
.detail-button {
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.detail-button:hover {
  background-color: #ccc;
}

/* Styling for the New Booking box */
.new-booking-box {
  border: 1.5px dashed #000;
  background-color: #fafafa;
  transition: background-color 0.3s, transform 0.2s;
}

.new-booking-box:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.new-booking-box:active {
  transform: scale(0.98);
}

.new-booking-text {
  font-size: 1.4rem;
  font-weight: bold;
}
