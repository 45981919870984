.operation-admin-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .operation-admin-container h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .tabs-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .tab-button {
    padding: 10px 20px;
    border: 1px solid #000;
    background: #fff;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-button.active {
    background-color: #28a745;
    color: #fff;
  }
  
  .tab-button:hover:not(.active) {
    background-color: #f0f0f0;
  }
  
  .loading-indicator {
    text-align: center;
    margin-top: 50px;
    font-size: 1rem;
  }
  
  .admin-content {
    display: flex;
    gap: 20px;
  }
  
  /* .sidebar {
    width: 300px;
    border: 1px solid #000;
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .sidebar-header h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
  } */
  
  .create-button {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .create-button:hover {
    background-color: #28a745;
    color: #fff;
  }
  
  .item-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    font-size: 0.9rem;
    border-top: 1px solid #000;
  }
  
  .item-list li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .item-list li.selected {
    background-color: #28a745;
    color: #fff;
  }
  
  .item-list li:hover:not(.selected) {
    background-color: #f0f0f0;
  }
  
  .no-items {
    padding: 10px;
    color: #666;
    font-style: italic;
  }

  .search-container {
    margin-bottom: 10px;
  }
  
  .search-container input[type="text"] {
    width: 90%;
    padding: 5px;
    border: 1px solid #000;
    font-size: 0.9rem;
  }
  
  .detail-panel {
    flex: 1;
    border: 1px solid #000;
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  .detail-panel h3 {
    margin-top: 0;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .field-instructions {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .detail-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #333;
  }
  
  .detail-form input[type="text"],
  .detail-form input[type="number"],
  .detail-form textarea,
  .detail-form select {
    width: 100%;
    padding: 5px;
    border: 1px solid #000;
    background: #fff;
    margin-top: 5px;
    font-size: 0.9rem;
  }
  
  .detail-form textarea {
    height: 80px;
    resize: vertical;
  }
  
  .buttons-row {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }
  
  .save-button,
  .cancel-button {
    padding: 8px 12px;
    border: 1px solid #000;
    background: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .save-button:hover {
    background-color: #28a745;
    color: #fff;
  }
  
  .cancel-button:hover {
    background-color: #dc3545;
    color: #fff;
  }
  
  .read-only-hint {
    font-size: 0.9rem;
    color: #666;
    margin-top: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .admin-content {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  