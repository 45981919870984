/* src/Apply.css */

.apply-container {
    max-width: 600px;
    margin: 80px auto 20px auto; /* Top margin to avoid overlap with top menu */
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;

}

.apply-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 1rem;
}

.form-group input[type="text"],
.form-group input[type="url"],
.form-group input[type="email"],
.form-group textarea,
.form-group select {
    padding: 8px 10px;
    border: 1px solid #000;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="url"]:focus,
.form-group textarea:focus,
.form-group select:focus {
    border-color: #5cb85c;
    outline: none;
}

.form-group input[type="file"] {
    padding: 5px 0;
}

.error {
    color: #d9534f;
    font-size: 0.875rem;
    margin-top: 5px;
}

.success-message {
    color: #5cb85c;
    text-align: center;
    margin-bottom: 15px;
    font-size: 1rem;
}

.apply-container button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.apply-container button[type="submit"]:hover {
    background-color: #0056b3;
    color: #fff;
}

.submit-error {
    color: #d9534f;
    text-align: center;
    margin-top: 10px;
}

/* Style for program explanations */
.program-explanation {
    background-color: #f9f9f9;
    border-left: 4px solid #007BFF;
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-style: italic;
    color: #333;
    font-size: 0.9rem;
}


/* Placeholder styling */
.apply-container input::placeholder,
.apply-container textarea::placeholder {
    font-size: 0.875rem; /* Smaller font size */
    color: #a9a9a9; /* Optional: change color for better visibility */
    opacity: 1; /* Ensures the color is fully opaque */
}

/* Vendor prefixes for broader browser support */
.apply-container input::-webkit-input-placeholder,
.apply-container textarea::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #a9a9a9;
    opacity: 1;
}

.apply-container input::-moz-placeholder,
.apply-container textarea::-moz-placeholder {
    font-size: 0.875rem;
    color: #a9a9a9;
    opacity: 1;
}

.apply-container input:-ms-input-placeholder,
.apply-container textarea:-ms-input-placeholder {
    font-size: 0.875rem;
    color: #a9a9a9;
    opacity: 1;
}

.apply-container input:-moz-placeholder,
.apply-container textarea:-moz-placeholder {
    font-size: 0.875rem;
    color: #a9a9a9;
    opacity: 1;
}


/* Optional: Responsive adjustments */
@media (max-width: 600px) {
    .apply-container {
        padding: 15px;
    }

    .program-explanation {
        padding: 8px 12px;
    }

    .apply-container h2 {
        font-size: 1.3rem;
    }
}


/* Style for mix upload fields */
.mix-upload {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.mix-upload input[type="file"] {
	flex: 1;
}

.add-mix-btn,
.remove-mix-btn {
	padding: 5px 10px;
	border: 1px solid black;
	cursor: pointer;
	font-size: 0.9rem;
	transition: background-color 0.3s;
}

.remove-mix-btn {
	background-color: #fff;
}

.add-mix-btn:hover {
	background-color: #5cb85c;
}

.remove-mix-btn:hover {
	background-color: #c82333;
}