html {
  zoom: 0.9; /* works in Chrome/Edge/Safari/Opera */
  -moz-transform: scale(0.9);      /* for Firefox */
  -moz-transform-origin: top left; /* anchor at top-left */
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Toast styles */
.Toastify__toast {
  padding: 16px;
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 0!important;
}

.Toastify__toast--success {
  background: #4caf50;  /* green background for success */
}

.Toastify__toast--error {
  background: #f44336;  /* red background for errors */
}

.Toastify__toast-body {
  font-size: 16px;
}

/* Progress bar styling */
.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.7);
}
