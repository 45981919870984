.content-event-detail-container {
    max-width: 1200px;
    margin: 80px auto 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
}

.detail-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.detail-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.back-arrow {
    cursor: pointer;
    font-size: 1.5rem;
    user-select: none;
}

.performances-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.performance-box {
    display: flex;
    border: 1px solid #000;
    background: #fff;
    transition: background-color 0.3s;
    align-items: flex-start;
}

.performance-box:hover {
    background-color: #f9f9f9;
}

.performance-thumbnail {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.performance-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.thumbnail-placeholder {
    width: 100%;
    height: 100%;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: #666;
}

.video-thumbnail img {
    max-width: 250px;
    background: #fafafa;
    font-size: 0.8rem;
    color: #666;
}

.performance-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
}

.performance-info p {
    margin: 5px 0;
    font-size: 0.95rem;
}

.performance-info a {
    color: #0056b3;
    text-decoration: none;
}

.performance-info a:hover {
    text-decoration: underline;
}

.toggle-extra-info-btn {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
    align-self: start;
}

.toggle-extra-info-btn:hover {
    background-color: #ccc;
}

.extra-info {
    margin-top: 15px;
    border-top: 1px solid #000;
    padding-top: 10px;
    font-size: 0.9rem;
}

.preserve-whitespace {
    white-space: pre-wrap;
}

.extra-info p {
    margin: 5px 0;
}

@media (max-width: 600px) {
    .performance-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .performance-thumbnail {
        width: 100%;
        height: auto; 
        max-height: 200px;
    }

    .performance-thumbnail img,
    .thumbnail-placeholder {
        width: 100%;
        height: auto;
    }
}
