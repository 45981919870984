/* BookingDetail.css */

/* Override certain global styles if needed */
.translate-button {
  position: absolute;
  top: 40px;
  right: 18px;
  z-index: 999;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
}

/* Overall container, no round corners */
.booking-detail-container {
  max-width: 700px;
  margin: 0px auto 20px auto; /* top margin for spacing from header */
  padding: 20px;
  background-color: #fff;
  /* border: 1px solid #000; */
  position: relative;
  border-radius: 0; /* no round corners */
  box-sizing: border-box;
}

/* Back arrow in top-left */
.back-arrow {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
}

/* Title at the top */
.booking-detail-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

/* The box that holds the main booking info */
.booking-info-wrapper {
  margin-bottom: 20px;
}

/* Main info container, no round corners */
.booking-info {
  border: 1px solid #000;
  background-color: #fafafa;
  padding: 15px;
  margin-top: 10px;
  border-radius: 0;
  box-sizing: border-box;
}

/* Row for each field: label 1/4, value 3/4 */
.row-field {
  display: flex;
  align-items: center;
  margin: 8px 0; /* consistent spacing */
  line-height: 1.5; /* uniform line-height */
}

.row-field .label {
  flex: 0 0 25%; /* 1/4 width */
  text-align: left; /* left-aligned label */
  font-weight: 600;
  padding-right: 0.5rem;
}

.row-field .value {
  flex: 1; /* 3/4 width */
  text-align: left;
  display: flex;
  flex-wrap: wrap; /* allow editing items to wrap if needed */
  align-items: center;
  gap: 0.5rem;
}

/* Info box for upcoming/past messages, no corners */
.info-box {
  border: 2px dotted #000;
  background-color: #ffecec;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 0;
  box-sizing: border-box;
  font-style: italic;
  color: #333;
}

.info-box ul {
  margin-top:20px;
  list-style-type:circle;
}

.info-box ul li {
  padding-bottom: 10px;
}

/* Buttons area (e.g., Cancel Booking) */
.detail-buttons {
  margin-top: 0.8rem;
  display: flex;
  gap: 0.5rem;
}

.detail-buttons .delete-button {
  padding: 0.4rem 0.8rem;
  border: 1px solid black;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;
}

.detail-buttons .delete-button:hover {
  background-color: #eee;
}

/* Edit row for inline buttons: no forced multi-line */
.edit-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

/* Buttons in the row (black text on white) */
.edit-row .edit-button,
.edit-row .save-button,
.edit-row .cancel-button {
  padding: 0px 10px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.edit-row .edit-button:hover,
.edit-row .save-button:hover,
.edit-row .cancel-button:hover {
  background-color: #e0e0e0;
}

/* Promotional Materials box, no corners, no gap, 2 columns or stacked on mobile */
.promotional-section {
  margin-top: 1.5rem;
}

.promotional-section h3 {
  margin-bottom: 10px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
}

/* 2 columns, each 50% for large screens */
.promo-box {
  border: 1px solid black;
  padding: 1rem;
  background-color: #fafafa;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  border-radius: 0;
  box-sizing: border-box;
}

.thumbnail-section,
.flyer-section {
  flex: 0 0 48%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align to top */
  align-items: center;
}

.thumbnail-section.align-top,
.flyer-section.align-top {
  align-items: flex-start; /* override to top-left if needed */
}

.thumbnail-section img,
.flyer-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 0;
  box-sizing: border-box;
}

.flyer-placeholder-2, .thumbnail-placeholder-2 {
  flex: 0 0 48%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align to top */
  align-items: center;
  height: 360px;
  border: 1px solid #ccc;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.thumbnail-placeholder-2 {
  height: 280px!important;
}
/* Post-event materials section, no corners */
.postevent-section {
  margin-top: 1.5rem;
}

.postevent-section h3 {
  margin-bottom: 10px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
}

.postevent-box {
  border: 1px solid black;
  padding: 1rem;
  background-color: #fafafa;
  border-radius: 0;
  box-sizing: border-box;
}

/* Highlight Snippets */
.highlight-snippets {
  margin-top: 1rem;
}

.snippet-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.snippet-item {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 0;
  box-sizing: border-box;
}

.snippet-item video {
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
  border: 1px solid #000;
  box-sizing: border-box;
}

.snippet-download-btn {
  border: 1px solid black;
  background-color: #fff;
  color: #000;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9rem;
  border-radius: 0;
  box-sizing: border-box;
}

.snippet-download-btn:hover {
  background-color: #eee;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .booking-detail-container {
    margin: 20px auto;
    padding: 10px;
  }

  /* On small screens, labels can take 40% if you prefer. */
  .row-field .label {
    flex: 0 0 40%;
    text-align: left;
  }
  .row-field .value {
    flex: 1;
  }

  .thumbnail-section,
  .flyer-section {
    width: 100%;
    margin-bottom: 1rem;
    align-items: flex-start; /* top-left */
  }

  .snippet-row {
    flex-direction: column;
    gap: 1rem;
  }
  .snippet-item {
    width: 100%;
    max-width: 100%;
  }
}
