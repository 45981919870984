/* Container for entire page */
.workshop-classes-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  font-family: sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Section backgrounds & layout */
.foundation-courses {
  background-color: #f9fcf5; /* a softer greenish-white */
  padding: 20px;
  border-radius: 8px;
}

.core-advanced-courses {
  background-color: #fff3e6; /* peach tone */
  padding: 20px;
  border-radius: 8px;
}

.specialized-electives {
  background-color: #f0f8ff; /* very light blue */
  padding: 20px;
  border-radius: 8px;
}

.workshops {
  background-color: #f5f1ff; /* very light purple */
  padding: 20px;
  border-radius: 8px;
}

/* Section Titles & Subtitles */
h2 {
  margin-bottom: 10px;
  font-size: 1.8rem;
}

.subtitle {
  font-weight: bold;
  margin-bottom: 15px;
}

/* Course Card */
.course-card {
  background-color: #fff;
  padding: 16px 20px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

/* Course Heading */
.course-card h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.2rem;
}

/* Basic Info lines (Sessions, Price) */
.course-card p {
  margin: 6px 0;
  line-height: 1.4;
}

/* Toggle Button */
.toggle-button {
  background-color: #4f4fff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

.toggle-button:hover {
  background-color: #3a3ac7;
}

/* Expanded course details area */
.course-details {
  margin-top: 12px;
  padding: 10px;
  border-top: 1px solid #ddd;
}

/* Chinese translation styling */
.chinese {
  color: #666;
  font-style: italic;
  margin-left: 6px;
  white-space: pre-wrap;
}
