.user-admin-container {
    max-width: 600px;
    margin: 80px auto 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-admin-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

.user-admin-container p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 0.9rem;
}

.user-table th, .user-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
    vertical-align: middle;
}

.user-table th {
    background-color: #f8f8f8;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 0.8rem;
}

.user-table td {
    white-space: nowrap;
}

/* Style for checkboxes */
.user-table input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* Disabled checkbox styling */
.user-table input[type="checkbox"][disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Button styling */
.user-table button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 0;
}

.user-table button:hover {
    background-color: #0056b3;
    color: #fff;
}
